import Header from 'components/Header';
import PageContainer from 'components/PageContainer';
import { useEffect, useMemo, useState } from 'react';

import { SurveyViewer } from '@momos/react-elements';
import ContactDetails from 'components/ContactDetails';
import SelectLocation from 'components/SelectLocation';
import MethodOfOrder from 'components/Survey/MethodOfOrder';
import RateExperience from 'components/Survey/RateExperience';
import SelectPlatform from 'components/Survey/SelectPlatform';
import ThankYou from 'components/Survey/ThankYou';
import WelcomePage from 'components/Survey/Welcome';

import { Spin } from 'antd';
import MetaHeader from 'components/MetaHeader';
import PoweredByMomos from 'components/PoweredByMomos';
import NoVoucher from 'components/Survey/NoVoucher';
import SelectStaff from 'components/Survey/SelectStaff';
import ShareFeedback from 'components/Survey/ShareFeedback';
import StaffRateExperience from 'components/Survey/StaffRateExperience';
import SurveyVoucherPageWrap from 'components/SurveyVoucherPageWrap';
import endpoints from 'config/endpoints';
import request from 'config/request';
import { ITEMS_SALES_BASE_URL, ITEMS_SALES_PUBLIC_TOKEN, REGION, SHOULD_FETCH_ORDER_ITEMS_DETAILS } from 'constant';
import { TRACKING } from 'constant/analytics';
import {
  SOCIAL_MEDIA_PLATFORM,
  SURVEY_ORDER_TYPES,
  SURVEY_QUESTIONS_TYPE,
  SURVEY_STEPS,
  SURVEY_TEMPLATES,
  SURVEY_TYPE,
  ThirdPartyLinksType,
} from 'constant/constants';
import {
  PARTNER,
  SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS,
  SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS_NAMES,
} from 'constant/partner';
import { breakpoints } from 'constant/style';
import usePosition from 'hooks/usePosition';
import Cookies from 'js-cookie';
import * as ga from 'lib/ga';
import { log } from 'lib/Logger';
import {
  capitalize,
  find,
  get,
  head,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isObject,
  omit,
  orderBy,
  some,
  toNumber,
  toUpper,
  trim,
} from 'lodash';
import moment from 'moment';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import qs from 'query-string';
import { fetchOrderItemsDetailByMomosOrderId, getMicrositeBySlug, getSurveyData, getVouchers } from 'services';
import styled from 'styled-components';
import { getApiUrl, haversineDistance, isClient, sortOutlets } from 'utils/common';
import { findServiceType, getSurveyObject, queryParamCleanUp } from 'utils/helper';
import showNotification from 'utils/notification';
import {
  MicrositeData,
  Outlet,
  PAGE_KEYS,
  Page,
  Staff,
  SurveyData,
  SurveyOutlet,
  SurveyQuestion,
  SurveyResultType,
  SurveySubmissionAnswerType,
  SurveySubmissionPayloadType,
  SurveySubmissionType,
  Voucher,
} from 'utils/types';
import { v4 as uuidv4 } from 'uuid';
import { IncomingMessage } from 'http';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';
import { ParsedUrlQuery } from 'querystring';

interface SurveyProps {
  data: SurveyData;
  vouchersData: Array<Voucher>;
  slug: string;
  micrositeData: MicrositeData;
  orderData: any;
  customHeaders: {
    'device-session-id': string;
    'x-session-id': string;
    'x-survey-id': string;
    'x-brand-slug': string;
  };
}

interface fetchOrderDataProps {
  orderId?: any;
  orderPlatform?: any;
  momosOrderId?: any;
  req?: any;
  slug?: any;
}

const GOOD_RATING = 4;
let hasSurveyBeenSubmitted = false;

export default function Survey({
  data,
  vouchersData = [],
  slug,
  micrositeData,
  orderData,
  customHeaders,
}: SurveyProps) {
  const useableVouchers = (vouchersData || [])?.filter((eachVoucher) => !eachVoucher?.unusable);
  const withVoucher = !!useableVouchers?.length;
  const router = useRouter();
  const {
    serviceType,
    platform,
    outletId,
    reviewText,
    orderId: eOrderId,
    name,
    email,
    source,
    sourceType,
    payment,
    receiptId,
    orderTime,
    lat,
    long,
    phone,
    phoneNumber,
    rating,
  }: any = queryParamCleanUp(router.query);
  console.log('orderData', orderData);
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<SurveyOutlet | null | undefined>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [orderType, setOrderType] = useState<string | null>(null);
  const [starRating, setStarRating] = useState<number | null>(null);
  const [orderDate, setOrderDate] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string | null>(eOrderId);
  const [isOrderItemDetailsLoaded, setIsOrderItemDetailsLoaded] = useState<boolean>(false);
  const [orderItemDetails, setOrderItemDetails] = useState<Record<string, any> | null>(null);
  const [surveyQuestionAnswered, setSurveyQuestionAnswered] = useState<Record<string, any> | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [navigationHistory, setNavigationHistory] = useState<string[]>([]);
  const [staffList, setStaffList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [shareLinks, setShareLinks] = useState<{ link: string; name: string }[]>([]);
  const [surveyResult, setSurveyResult] = useState<SurveyResultType>();
  const [outletsNearBy, setOutletNearBy] = useState<Outlet[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  const expressQueryParams = omit(router.query, ['id', 'slug', 'fbclid']);
  const isExpressSurvey = !isEmpty(expressQueryParams);
  // reconstruct orderItemList itemList

  const formattedPlatform = platform ? capitalize(platform) : null;

  const { surveyData } = data;
  const surveyTemplate = surveyData?.surveyData?.template?.name || SURVEY_TEMPLATES.STD_FEEDBACK_SURVEY;

  const { latLong }: any = usePosition(surveyData?.version !== 'v2');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pages: Page[] = surveyData?.surveyPages ?? [];

  //filter out duplicate items based on itemId
  const groupedItems: any[] = [];
  let counter = 0;
  orderData.itemData.forEach((item: any) => {
    let menuItemId = item.rawData?.menuItemId;
    if (!menuItemId) {
      menuItemId = counter++;
    }

    if (!groupedItems[menuItemId]) {
      groupedItems[menuItemId] = item;
    }
  });

  //sort order item data
  let groupedItemsArr: any[] = Object.values(groupedItems);
  groupedItemsArr = orderBy(groupedItemsArr, ['total', 'quantity', 'name'], ['desc', 'desc', 'desc']);

  let itemList: string[] = groupedItemsArr.map((o: any) => {
    return o.name;
  });

  //filtering rejected list as well
  const itemRejectionList = surveyData?.settings?.itemRejectionList ?? [];

  itemList = itemList.filter((item) => !itemRejectionList.includes(item.trim()));

  const activeServiceTypes = pages
    .find((p) => p.pageKey === PAGE_KEYS.TYPE_OF_ORDER)
    ?.value?.find((f) => f.fieldKey === 'order_option')?.fieldValue;
  const formattedOrderType = useMemo(() => {
    return findServiceType(serviceType, activeServiceTypes);
  }, [serviceType, activeServiceTypes]);

  const [initialPageKey, setInitialPageKey] = useState<string | null>(null);
  const [currentPageKey, setCurrentPageKey] = useState(PAGE_KEYS.WELCOME_PAGE);

  const brandColor = get(surveyData, 'surveyData.surveyTheme.brandColor');

  const filteredOutlets: Outlet[] = useMemo(() => {
    return sortOutlets(latLong, surveyData?.outlets);
  }, [latLong, surveyData?.outlets]);

  const fetchOrderItemDetails = async ({
    orderId,
    orderPlatform,
  }: {
    orderId: string | null;
    orderPlatform: string | null;
  }): Promise<{
    success: boolean;
    orderData?: Record<string, any> | null;
  }> => {
    if (isEmpty(orderId) || isEmpty(orderPlatform)) {
      console.info({ orderId, orderPlatform }, 'No input data found for fetching order items details');
      return { success: false };
    }
    try {
      const queryString = qs.stringify({
        platform: orderPlatform,
        orderId,
      });
      const response = await request.get(`${ITEMS_SALES_BASE_URL}/momos/item-sales?${queryString}`, {
        headers: {
          Authorization: `Bearer ${ITEMS_SALES_PUBLIC_TOKEN}`,
          'Access-Control-Allow-Origin': '*',
          silentFetch: true,
        },
      });
      if (isEqual(response?.status, 200) && !isEmpty(get(response, 'data'))) {
        return { success: true, orderData: { orderId, orderPlatform, orderItemDetails: get(response, 'data') } };
      }
      return { success: false };
    } catch (err) {
      console.error(
        { err, orderId, orderPlatform },
        `An error occured while fetching order items details for survey submission`,
      );
      return { success: false };
    }
  };

  useEffect(() => {
    (async () => {
      // Fetch the order item details for post order SMS flow
      try {
        const orderPlatform = SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS_NAMES[source || platform];
        if (
          !isOrderItemDetailsLoaded &&
          isExpressSurvey &&
          !isEmpty(orderId) &&
          isEqual(SHOULD_FETCH_ORDER_ITEMS_DETAILS, 'true') &&
          SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS.includes(orderPlatform)
        ) {
          // here we are calling api
          const { success, orderData } = await fetchOrderItemDetails({
            orderId,
            orderPlatform,
          });
          if (success && !isEmpty(orderData)) {
            setOrderItemDetails(orderData as any);
          }
          setIsOrderItemDetailsLoaded(true);
        }
      } catch (error) {
        setIsOrderItemDetailsLoaded(true);
        console.error({ err: error }, 'An error occured while pulling post order SMS order details');
      }
    })();
  }, [orderId, surveyData, isOrderItemDetailsLoaded, source, platform, isExpressSurvey]);

  useEffect(() => {
    if (formattedOrderType) {
      setOrderType(formattedOrderType);
    }
    if (formattedPlatform) {
      setSelectedPlatform(formattedPlatform);
    }
    if (outletId) {
      const outlet = surveyData.outlets.find((o) => isEqual(o.id, outletId));
      if (outlet) {
        setSelectedLocation(outlet);
      }
    }

    if (orderTime) {
      setOrderDate(moment(decodeURIComponent(orderTime)).format());
    }
    if (rating) {
      setStarRating(Number(rating));
    }
    if (lat && long) {
      const nearByOutlets: Outlet[] = [];
      surveyData?.outlets.forEach((outlet) => {
        if (!isEmpty(outlet.latLong) && haversineDistance([lat, long], outlet.latLong) <= 0.3) {
          nearByOutlets.push(outlet);
        }
      });
      if (!isEmpty(nearByOutlets)) {
        if (isEqual(nearByOutlets.length, 1)) {
          setSelectedLocation(head(nearByOutlets));
        } else {
          setOutletNearBy(nearByOutlets);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType, platform, outletId, orderDate]);

  useEffect(() => {
    setTimeout(() => {
      setPageLoader(false); // page loader for survey
    }, 700);

    if (surveyData && surveyData?.questions?.length) {
      const surveyQuestionAnsweredObj: Record<string, any> = {};
      surveyData?.questions?.map((question) => {
        surveyQuestionAnsweredObj[question.id] = {};
      });
      setSurveyQuestionAnswered(surveyQuestionAnsweredObj);
    }
  }, [surveyData]);

  // useEffect(() => {
  //   console.log('>>> surveyQuestionAnswered: ', surveyQuestionAnswered);
  // }, [surveyQuestionAnswered]);

  useEffect(() => {
    console.log('>> pages > SurveyViewer', pages);
  }, [pages]);

  useEffect(() => {
    (async () => {
      const getCategoryName = (categoryId: string) => {
        return data?.categories?.find((o) => isEqual(o.id, categoryId))?.category;
      };

      const linksData: { link: string; name: string }[] = [];

      if (selectedLocation?.thirdPartyLinks?.length) {
        for (const link of selectedLocation?.thirdPartyLinks ?? []) {
          if (
            isEqual(link.type, ThirdPartyLinksType.SOCIAL_MEDIA) &&
            isEqual(getCategoryName(link.categoryId), capitalize(PARTNER.FACEBOOK))
          ) {
            const linkId = link.link?.split('/')?.pop();
            if (linkId) {
              // const response = await fetch(
              //   `${endpoints.facebook.graph}/${linkId}/ratings?access_token=${FACEBOOK_GRAPH_API_ACCESS_TOKEN}`,
              // );

              // if (isEqual(response.status, 403) || isEqual(response.status, 200)) {
              linksData.push({ link: `${link?.link}/reviews`, name: PARTNER.FACEBOOK });
              // } else {
              //   const facebookBrandLinkId = data?.categories?.find((o) =>
              //     isEqual(o.category, capitalize(PARTNER.FACEBOOK)),
              //   )?.id;

              //   if (facebookBrandLinkId) {
              //     const facebookBrandLink = data?.brandDetails?.thirdPartyLinks?.find((o) =>
              //       isEqual(o.categoryId, facebookBrandLinkId),
              //     );
              //     if (facebookBrandLink) {
              //       linksData.push({ link: `${facebookBrandLink.link}/reviews`, name: PARTNER.FACEBOOK });
              //     }
              //   }
              // }
            }
          } else if (
            isEqual(link.type, ThirdPartyLinksType.SOCIAL_MEDIA) &&
            isEqual(getCategoryName(link.categoryId), capitalize(SOCIAL_MEDIA_PLATFORM.YELP))
          ) {
            linksData.push({ link: link.link, name: SOCIAL_MEDIA_PLATFORM.YELP });
          }
        }
      }

      if (selectedLocation && selectedLocation.gmbReviewLink) {
        linksData.push({ link: selectedLocation.gmbReviewLink, name: PARTNER.GOOGLE });
      }

      if (!linksData.some((o) => o.name === PARTNER.FACEBOOK)) {
        const facebookBrandLinkId = data?.categories?.find((o) =>
          isEqual(o.category, capitalize(PARTNER.FACEBOOK)),
        )?.id;

        if (facebookBrandLinkId) {
          const facebookBrandLink = data?.brandDetails?.thirdPartyLinks?.find((o) =>
            isEqual(o.categoryId, facebookBrandLinkId),
          );
          if (facebookBrandLink) {
            linksData.push({ link: `${facebookBrandLink.link}/reviews`, name: PARTNER.FACEBOOK });
          }
        }
      }

      if (!linksData.some((o) => o.name === PARTNER.GOOGLE)) {
        const gmbBrandLinkId = data?.categories?.find((o) => isEqual(o.category, capitalize(PARTNER.GOOGLE)))?.id;

        if (gmbBrandLinkId) {
          const gmbBrandLink = selectedLocation?.thirdPartyLinks?.find((o) => isEqual(o.categoryId, gmbBrandLinkId));
          if (gmbBrandLink) {
            linksData.push({ link: gmbBrandLink.link, name: PARTNER.GOOGLE });
          }
        }
      }

      setShareLinks(linksData);
    })();
  }, [selectedLocation, data?.brandDetails?.thirdPartyLinks, data?.categories]);

  useEffect(() => {
    if (isClient()) {
      window.scrollTo(0, 0);
    }
  }, [selectedStep]);

  const fetchStaffAndProceed = async () => {
    const result = await request.get(`${endpoints.momos.staff.list}/${selectedLocation?.id}`);
    if (result.data?.status) {
      setStaffList(result.data?.staffList);
      if (result.data?.staffList?.length) {
        goToNextStep(SURVEY_STEPS.SELECT_STAFF);
      } else {
        goToNextStep(SURVEY_STEPS.CONTACT_DETAILS);
      }
    }
  };

  const goToNextStep = (step: string) => {
    setSelectedStep(step);
    setNavigationHistory([...navigationHistory, step]);
  };

  /**
   * Express Survey Handling
   * @param defaultRoute string
   */
  const checkExpressSurveyNavigation = (defaultRoute: string, { location, type }: Record<string, any>) => {
    let expressSurveyStep = defaultRoute;

    if (isExpressSurvey) {
      const selectedOrderType = type || orderType;
      const expressLocation = location || selectedLocation;

      if (selectedOrderType) {
        expressSurveyStep = SURVEY_STEPS.SELECT_LOCATION;
      }
      if (selectedOrderType && expressLocation) {
        expressSurveyStep = SURVEY_STEPS.RATE_EXPERIENCE;
      }
      if (selectedOrderType === SURVEY_ORDER_TYPES.DELIVERY && selectedPlatform && !expressLocation) {
        expressSurveyStep = SURVEY_STEPS.SELECT_LOCATION;
      }
      if (selectedOrderType === SURVEY_ORDER_TYPES.DELIVERY && !selectedPlatform && !expressLocation) {
        expressSurveyStep = SURVEY_STEPS.SELECT_LOCATION;
      }
      if (selectedOrderType === SURVEY_ORDER_TYPES.DELIVERY && !selectedPlatform && expressLocation) {
        expressSurveyStep = SURVEY_STEPS.SELECT_PLATFORM;
      }

      if (selectedOrderType === SURVEY_ORDER_TYPES.DELIVERY && selectedPlatform && expressLocation) {
        expressSurveyStep = SURVEY_STEPS.RATE_EXPERIENCE;
      }
    }
    goToNextStep(expressSurveyStep);
  };

  const onSelectOrderType = (type: string) => {
    setOrderType(type);
    ga.event(TRACKING.SURVEY.BUTTON[type]);
    checkExpressSurveyNavigation(SURVEY_STEPS.SELECT_LOCATION, {
      type,
    });
  };

  const {
    staffQuestion,
    masterQuestion,
  }: { staffQuestion: SurveyQuestion | undefined; masterQuestion: SurveyQuestion | undefined } = useMemo(() => {
    return {
      staffQuestion: surveyData?.questions?.find((o) => o.type === SURVEY_QUESTIONS_TYPE.STAFF),
      masterQuestion: surveyData?.questions?.find((o) => o.type === SURVEY_QUESTIONS_TYPE.MASTER),
    };
  }, [surveyData]);

  const getStarted = () => {
    ga.event(TRACKING.SURVEY.BUTTON.GET_STARTED);
    checkExpressSurveyNavigation(SURVEY_STEPS.METHOD_OF_ORDER, {});
  };

  const onSelectLocation = async (location: SurveyOutlet) => {
    setSelectedLocation(location);
    ga.event(TRACKING.SURVEY.BUTTON.LOCATION);
    if (orderType === SURVEY_ORDER_TYPES.DELIVERY) {
      checkExpressSurveyNavigation(SURVEY_STEPS.SELECT_PLATFORM, {
        location,
      });
    } else {
      if (staffQuestion && orderType === SURVEY_ORDER_TYPES.DINE_IN && staffQuestion.sortOrder === '1') {
        fetchStaffAndProceed();
      } else {
        checkExpressSurveyNavigation(SURVEY_STEPS.RATE_EXPERIENCE, {
          location,
        });
      }
    }
  };

  const onSelectPlatform = (platform: string) => {
    setSelectedPlatform(platform);
    ga.event(TRACKING.SURVEY.BUTTON.DELIVERY_PLATFORM);
    checkExpressSurveyNavigation(SURVEY_STEPS.RATE_EXPERIENCE, {});
  };

  const onSkipSelectStaff = () => {
    ga.event(TRACKING.SURVEY.BUTTON.STAFF_SKIP);
    if (staffQuestion && orderType === SURVEY_ORDER_TYPES.DINE_IN && staffQuestion.sortOrder === '1') {
      goToNextStep(SURVEY_STEPS.RATE_EXPERIENCE);
    } else {
      goToNextStep(SURVEY_STEPS.CONTACT_DETAILS);
    }
  };

  const onSelectStaff = (staff: Staff) => {
    ga.event(TRACKING.SURVEY.BUTTON.SELECT_STAFF);
    setSelectedStaff(staff);
    goToNextStep(SURVEY_STEPS.STAFF_RATE_EXPERIENCE);
  };

  const submitSurvey = async (contactInfo: { name: string; email: string; phoneNumber?: string }) => {
    if (selectedLocation && surveyQuestionAnswered) {
      const submissionsData: SurveySubmissionType[] = [];

      Object.keys(surveyQuestionAnswered)?.map((id) => {
        if (surveyQuestionAnswered[id]?.answer && surveyQuestionAnswered[id]?.detailedAnswer) {
          submissionsData.push({
            questionId: id,
            answerData: surveyQuestionAnswered[id],
          });
        }
      });

      const surveySubmissionPayload: SurveySubmissionPayloadType = {
        name: contactInfo.name,
        email: contactInfo.email,
        phoneNumber: contactInfo.phoneNumber,
        outletId: selectedLocation.id,
        serviceOption: {
          type: orderType,
          details: {
            platform: selectedPlatform,
            metaData: {
              gaSessionId: sessionStorage?.getItem?.('sessionId'),
            },
          },
        },
        submissions: submissionsData,
        timezoneOffset: new Date().getTimezoneOffset(),
      };
      if ((orderType === SURVEY_ORDER_TYPES.DINE_IN || orderType === SURVEY_ORDER_TYPES.TAKE_AWAY) && orderDate) {
        surveySubmissionPayload.serviceOption.details.date = orderDate;
      }
      if (orderId) {
        surveySubmissionPayload.serviceOption.details.orderId = orderId;
      }
      if (isExpressSurvey) {
        surveySubmissionPayload.surveySubmissionType = SURVEY_TYPE.EXPRESS;
        if (source) {
          surveySubmissionPayload.serviceOption.details.source = source;
        }
        if (sourceType) {
          surveySubmissionPayload.serviceOption.details.sourceType = sourceType;
        }
        if (payment) {
          surveySubmissionPayload.serviceOption.details.payment = payment;
        }
        if (receiptId) {
          surveySubmissionPayload.serviceOption.details.receiptId = payment;
        }
        if (orderItemDetails) {
          surveySubmissionPayload.orderItemDetails = orderItemDetails;
        }
      }

      const result = await request.post(
        `${endpoints.momos.survey.default}/${slug}/${surveyData?.id}`,
        surveySubmissionPayload,
      );

      if (result.data.status) {
        setSurveyResult(result.data);

        const rating = Number(masterQuestion ? surveyQuestionAnswered?.[masterQuestion?.id]?.answer : 0);
        const hasGoogleLink = some(shareLinks, { name: 'GOOGLE' });
        if (rating >= GOOD_RATING && hasGoogleLink) {
          goToNextStep(SURVEY_STEPS.SHARE_FEEDBACK);
        } else {
          goToNextStep(SURVEY_STEPS.THANK_YOU);
        }
        ga.event({
          ...TRACKING.VOUCHERS.DISPENSE_VOUCHER,
          value: withVoucher && vouchersData?.[0]?.id,
        });
      } else {
        showNotification(result.data.message, 'Error', 'error');
      }
    }
  };

  const onSubmitRateExperience = async ({
    orderDate,
    orderTime,
    orderId,
  }: {
    orderDate: moment.Moment;
    orderTime: moment.Moment;
    orderId: string;
  }) => {
    let orderDateFormat = null;
    if (orderDate && orderTime) {
      orderDateFormat = `${orderDate?.format('DD/MM/YYYY')} ${orderTime.format('hh:mm a')}`;
    }
    setOrderDate(orderDateFormat ? moment(orderDateFormat, 'DD/MM/YYYY hh:mm a').format() : null);
    setOrderId(orderId);

    if (staffQuestion && staffQuestion.sortOrder === '2' && orderType === SURVEY_ORDER_TYPES.DINE_IN) {
      fetchStaffAndProceed();
    } else {
      goToNextStep(SURVEY_STEPS.CONTACT_DETAILS);
    }
  };

  const onBack = () => {
    const surveyHistory = [...navigationHistory];
    const lastIndex = surveyHistory.length - 1;
    surveyHistory.splice(lastIndex, 1);
    setNavigationHistory(surveyHistory);
    setSelectedStep(surveyHistory[surveyHistory.length - 1] || null);
  };

  const onSubmitStaffExperience = () => {
    if (staffQuestion && staffQuestion.sortOrder === '2') {
      goToNextStep(SURVEY_STEPS.CONTACT_DETAILS);
    } else {
      goToNextStep(SURVEY_STEPS.RATE_EXPERIENCE);
    }
  };

  const findCustomPageQuestionsResponse = (responses: any) => {
    const customPageResponses = responses.filter(
      (response: any) => response.isCustomPage || response.isCustomQuestionAllowed,
    );

    const concatenatedValues = customPageResponses.reduce((result: any, response: { value: any }) => {
      if (response.value) {
        Object.assign(result, response.value);
      }
      return result;
    }, {});
    return concatenatedValues;
  };

  const getSelectedOffer = (pageValues: any) => {
    let resultValue: any = false;
    const page = find(pageValues, (page) =>
      some(page.value, (value) => (value as any)?.questionType === 'choose_voucher'),
    );
    if (page) {
      const pageValue = find(page.value, { questionType: 'choose_voucher' });
      resultValue = pageValue ? (pageValue as any).value : false;
    }
    if (resultValue === 'no_offer') {
      resultValue = false;
      return resultValue;
    }
    // If only 1 voucher is attached to the revision - and there is no selection OR page available , we'll submit that voucher for dispense.
    if (data.surveyData?.voucherIds?.length === 1) {
      resultValue = data.surveyData?.voucherIds[0];
    }
    return resultValue;
  };

  const submitSurveyV2 = async (pageValues: any, additonalValues: any) => {
    log.debug(
      {
        rawData: pageValues,
        additonalValues,
        ...customHeaders,
      },
      `Submit Survey V2: ${surveyData?.id}`,
    );
    if (hasSurveyBeenSubmitted) {
      return;
    }

    hasSurveyBeenSubmitted = true;
    const selectedVoucher = getSelectedOffer(pageValues);
    const experienceDetailsPage = pageValues?.find(
      (eachPage: Page) => eachPage.pageKey === PAGE_KEYS.EXPERIENCE_DETAILS,
    );
    const masterQuestion = surveyData?.questions?.find((o) => o.type === SURVEY_QUESTIONS_TYPE.MASTER);
    const staffQuestion = surveyData?.questions?.find((o) => o.type === SURVEY_QUESTIONS_TYPE.STAFF);
    const mediaIdList = (experienceDetailsPage?.value?.pictures || []).map(
      (eachMedia: Record<string, any>) => eachMedia.mediaId,
    );

    const surveyObject = getSurveyObject(pageValues);

    const surveySubmissionPayload: any = {
      name: surveyObject.name,
      isAnonymous: false,
      // email: surveyObject.email,
      phoneNumber: surveyObject.phoneNumber,
      outletId: surveyObject.outletId,
      serviceOption: {
        type: surveyObject.typeOfOrder,
        details: {
          platform: surveyObject.deliveryPlatform,
          orderId: surveyObject.orderReferenceNumber || undefined,
          date: surveyObject.orderDate || undefined,
        },
      },
      submissions: [],
      timezoneOffset: new Date().getTimezoneOffset(),
      ...(selectedVoucher ? { voucherId: selectedVoucher } : {}),
    };

    if (surveyObject.email) {
      surveySubmissionPayload.email = surveyObject.email;
    }

    if (!surveyObject.email && !surveyObject.phoneNumber) {
      surveySubmissionPayload.isAnonymous = true;
    }

    if (data.smsFeatureEnabled) {
      if (surveyObject.contactMethod && surveyObject.email && surveyObject.phoneNumber) {
        surveySubmissionPayload.contactMethod = surveyObject.contactMethod;
      } else if (surveyObject.email) {
        surveySubmissionPayload.contactMethod = 'EMAIL';
      } else if (surveyObject.phoneNumber) {
        surveySubmissionPayload.contactMethod = 'SMS';
      }
    }

    if (staffQuestion) {
      surveySubmissionPayload.submissions.push({
        questionId: staffQuestion?.id,
        answerData: {
          answer: surveyObject.staffRating || '',
          detailedAnswer: surveyObject.staffComment,
          staff: surveyObject.staff,
        },
      });
    }

    const customPageQuestionsResponse = findCustomPageQuestionsResponse(pageValues);
    if (!isEmpty(customPageQuestionsResponse)) {
      Object.keys(omit(customPageQuestionsResponse, ['main', 'rating']))?.forEach((key) => {
        const surveyRevisionSurveyQuestionId = surveyData?.questions?.find((o) => o.surveyQuestionId == key)?.id;
        const customQuestionObj = customPageQuestionsResponse[key];

        if (surveyRevisionSurveyQuestionId) {
          const answerObj: any = {
            questionId: surveyRevisionSurveyQuestionId,
            answerData: {},
          };

          if (customQuestionObj.isMasterQuestion) {
            answerObj.answerData.answer = surveyObject.normalizedRating;
            answerObj.answerData.detailedAnswer = customQuestionObj.value?.toString();
            answerObj.answerData.experienceData = { ...surveyObject.tagsPositive, ...surveyObject.tagsNegative };
            answerObj.answerData.media = mediaIdList || [];
          } else {
            const value = customQuestionObj.value;
            if (Array.isArray(value)) {
              answerObj.answerData.answer = value.join(', ');
            } else if (isObject(value)) {
              answerObj.answerData.answer = JSON.stringify(value);
            } else {
              answerObj.answerData.answer = value?.toString();
            }
            if (customQuestionObj?.itemName) {
              answerObj.answerData.itemName = customQuestionObj.itemName;
            }
            answerObj.answerData.questionType = customQuestionObj.questionType;
          }
          surveySubmissionPayload.submissions.push(answerObj);
        }
      });
    }

    const { submissions } = surveySubmissionPayload || {};

    // Helper function to create a submission object
    const createSubmission = (
      questionId: string,
      answer: string,
      detailedAnswer = '',
      experienceData = {},
      media = [],
    ) => ({
      questionId,
      answerData: {
        answer,
        detailedAnswer,
        experienceData,
        media,
      },
    });

    const mainQuestion = surveyData.questions.find((o) => toNumber(o.sortOrder) === 1) || surveyData.questions[0];

    // if main and master are same.in short (main question is on the experience detail page)
    if (masterQuestion?.id === mainQuestion.id && !some(submissions, (o) => o.questionId === mainQuestion.id)) {
      submissions.push(
        createSubmission(
          masterQuestion.id,
          surveyObject.normalizedRating,
          surveyObject.comment,
          { ...surveyObject.tagsPositive, ...surveyObject.tagsNegative },
          mediaIdList || [],
        ),
      );
    }

    // handle if main question is skiped
    if (!some(submissions, (o) => o.questionId === masterQuestion?.id) && masterQuestion) {
      submissions.push(
        createSubmission(
          masterQuestion.id,
          surveyObject.normalizedRating,
          '',
          { ...surveyObject.tagsPositive, ...surveyObject.tagsNegative },
          mediaIdList || [],
        ),
      );
    }

    // handle if main question as custom question
    if (mainQuestion && !some(submissions, (o) => o.questionId === mainQuestion?.id)) {
      submissions.push(createSubmission(mainQuestion.id, surveyObject.comment, '', {}, []));
    }

    if (isExpressSurvey) {
      surveySubmissionPayload.surveySubmissionType = SURVEY_TYPE.EXPRESS;
      if (source) {
        surveySubmissionPayload.serviceOption.details.source = isArray(source) ? source[0] : source;
      }
      if (sourceType) {
        surveySubmissionPayload.serviceOption.details.sourceType = sourceType;
      }
      if (payment) {
        surveySubmissionPayload.serviceOption.details.payment = payment;
      }
      if (receiptId) {
        surveySubmissionPayload.serviceOption.details.receiptId = payment;
      }
      surveySubmissionPayload.serviceOption.details.metaData = {
        queryParams: omit(router.query, ['id', 'slug']),
      };
    }

    if (!isEmpty(additonalValues)) {
      surveySubmissionPayload.serviceOption.details.metaData = {
        ...surveySubmissionPayload.serviceOption.details.metaData,
        boostdisplayed: additonalValues.boostdisplayed,
        gaSessionId: sessionStorage?.getItem?.('sessionId'),
      };
    }

    //filter bad survey submission here
    if (surveySubmissionPayload && surveySubmissionPayload.submissions && surveySubmissionPayload.submissions.length) {
      surveySubmissionPayload.submissions = surveySubmissionPayload.submissions.filter((obj: any) => {
        return obj.answerData && obj.answerData.answer != null;
      });
    }

    setLoading(true);

    let momosDeviceSessionId: any = null;
    try {
      if (Cookies) {
        momosDeviceSessionId = Cookies.get('momosSessionId');
        if (!momosDeviceSessionId) {
          momosDeviceSessionId = uuidv4();
          Cookies.set('momosSessionId', momosDeviceSessionId);
        }
      }
    } catch (error) {
      console.log('Error while reading cookies');
    }

    return new Promise((resolve, reject) => {
      request
        .post(`${endpoints.momos.survey.default}/${slug}/${surveyData?.id}`, surveySubmissionPayload, {
          headers: { ...(customHeaders || {}), 'device-session-id': momosDeviceSessionId },
        })
        .then((result) => {
          setLoading(false);

          if (result.data.status) {
            resolve(true);
            setSurveyResult(result.data);
          } else {
            resolve(false);
            showNotification(result.data.message, 'Error', 'error');
          }
        })
        .catch((error: any) => {
          console.error(error);
          setLoading(false);
          reject(error);
          hasSurveyBeenSubmitted = false;
        });
    });
  };

  const handleAssistedReview = async () => {
    ga.event(TRACKING.SURVEY.BUTTON.SHARE.GOOGLE);
    const surveyResponseMetadata = get(surveyResult, '__metadata', {});
    // Capture the user click event for assisted reviews
    await request.post(
      `${endpoints.momos.survey.default}/${slug}/${surveyData?.id}/assisted-reviews`,
      {
        platform: 'GOOGLE',
        surveyRevisionId: get(surveyResponseMetadata, 'surveyRevisionId'),
        surveySubmitterId: get(surveyResponseMetadata, 'surveySubmitterId'),
        surveySubmissionId: get(surveyResponseMetadata, 'surveySubmissionId'),
      },
      {
        headers: { ...(customHeaders || {}) },
      },
    );
  };

  const renderSurvey = () => {
    switch (selectedStep) {
      case SURVEY_STEPS.METHOD_OF_ORDER:
        return <MethodOfOrder onSelect={onSelectOrderType} onBack={onBack} brandColor={brandColor} />;

      case SURVEY_STEPS.SELECT_PLATFORM:
        return (
          <SelectPlatform
            onSelect={onSelectPlatform}
            onBack={onBack}
            selectedLocation={selectedLocation}
            categories={data?.categories}
            brand={data.brandDetails}
            brandColor={brandColor}
          />
        );

      case SURVEY_STEPS.RATE_EXPERIENCE:
        return (
          <RateExperience
            data={masterQuestion}
            surveyQuestionAnswered={masterQuestion ? surveyQuestionAnswered?.[masterQuestion?.id] : null}
            setSurveyQuestionAnswered={(answers: SurveySubmissionAnswerType) => {
              if (masterQuestion?.id) {
                setSurveyQuestionAnswered({ ...surveyQuestionAnswered, [masterQuestion?.id]: answers });
              }
            }}
            onSubmit={onSubmitRateExperience}
            onBack={onBack}
            orderType={orderType}
            surveyId={surveyData?.id}
            slug={slug}
            fileList={fileList}
            setFileList={setFileList}
            orderDate={orderDate}
            orderId={orderId}
            reviewText={reviewText}
          />
        );

      case SURVEY_STEPS.CONTACT_DETAILS:
        return (
          <ContactDetails
            slug={slug}
            voucher={
              withVoucher && surveyTemplate !== SURVEY_TEMPLATES.STD_FEEDBACK_SURVEY_V2 ? useableVouchers[0] : undefined
            }
            onSubmit={submitSurvey}
            onBack={onBack}
            brandName={data.brandDetails.name}
            brandLogo={data.brandDetails.logoUrl}
            name={trim(name)}
            email={trim(email)}
            surveyId={surveyData.id}
            region={data?.brandDetails?.region}
          />
        );

      case SURVEY_STEPS.SELECT_LOCATION:
        return (
          <SelectLocation
            title="Which location did you place the order from?"
            outlets={outletsNearBy.length > 0 ? outletsNearBy : filteredOutlets}
            onSelect={onSelectLocation}
            onBack={onBack}
          />
        );

      case SURVEY_STEPS.SELECT_STAFF:
        return (
          <SelectStaff data={staffQuestion} staffList={staffList} onSelect={onSelectStaff} onSkip={onSkipSelectStaff} />
        );

      case SURVEY_STEPS.STAFF_RATE_EXPERIENCE:
        return (
          <StaffRateExperience
            data={staffQuestion}
            selectedStaff={selectedStaff}
            surveyQuestionAnswered={staffQuestion ? surveyQuestionAnswered?.[staffQuestion?.id] : null}
            setSurveyQuestionAnswered={(answers) => {
              if (staffQuestion?.id) {
                setSurveyQuestionAnswered({ ...surveyQuestionAnswered, [staffQuestion?.id]: answers });
              }
            }}
            onSubmit={onSubmitStaffExperience}
          />
        );

      case SURVEY_STEPS.SHARE_FEEDBACK:
        return (
          <ShareFeedback
            onSkip={() => {
              goToNextStep(SURVEY_STEPS.THANK_YOU);
            }}
            voucher={withVoucher}
            slug={slug}
            surveyResponseMetadata={get(surveyResult, '__metadata', {})}
            surveyId={get(surveyData, 'id', null)}
            links={shareLinks}
            reviewText={masterQuestion ? surveyQuestionAnswered?.[masterQuestion?.id]?.detailedAnswer : ''}
          />
        );

      case SURVEY_STEPS.THANK_YOU: {
        const rating = Number(masterQuestion ? surveyQuestionAnswered?.[masterQuestion?.id]?.answer : 0);
        return (
          <ThankYou
            rating={rating}
            title="Thank you so much for your feedback!"
            voucher={withVoucher}
            voucherText={
              surveyResult?.voucherDispenseError
                ? 'You have already redeemed the voucher for this survey. We hope to serve you again!'
                : undefined
            }
            brandColor={brandColor}
            micrositeData={micrositeData}
          />
        );
      }

      default:
        if (withVoucher) {
          return (
            <WelcomePage
              name={surveyData?.name}
              voucherData={
                withVoucher && surveyTemplate !== SURVEY_TEMPLATES.STD_FEEDBACK_SURVEY_V2
                  ? useableVouchers[0]
                  : undefined
              }
              onStart={getStarted}
              brand={data.brandDetails}
            />
          );
        } else {
          return <NoVoucher name={surveyData?.name} onStart={getStarted} />;
        }
    }
  };

  if (!data?.brandDetails) {
    return null;
  }

  if (surveyData?.version == 'v2') {
    const apiBaseUrl = getApiUrl() || '';

    const phoneNumberParam = phoneNumber || phone;

    const expressParams = {
      orderType,
      selectedPlatform,
      outletId: Array.isArray(outletId) ? outletId?.[0] : outletId,
      reviewText,
      orderDate: orderTime,
      orderTime,
      orderId: eOrderId,
      name: Array.isArray(name) ? name?.[0] : name,
      email: Array.isArray(email) ? email?.[0] : email,
      lat,
      long,
      phoneNumber: Array.isArray(phoneNumberParam) ? phoneNumberParam?.[0] : phoneNumberParam,
      rating: starRating,
    };

    if (pageLoader) {
      return (
        <PageLoaderWrap>
          <Spin />
        </PageLoaderWrap>
      );
    }

    const fontFamily = get(surveyData, 'surveyData.surveyTheme.font');

    return (
      <>
        <MetaHeader
          title={surveyData?.name}
          image={data.brandDetails.logoUrl ? data.brandDetails.logoUrl : undefined}
          pixelId={data?.micrositeConfig?.micrositePixelId}
          fontFamilies={fontFamily ? [fontFamily] : []}
        />
        <StyledSurveyWrap>
          <SurveyViewer
            trackEvent={ga.event}
            pages={pages}
            initialPageKey={initialPageKey}
            setInitialPageKey={setInitialPageKey}
            currentPageKey={currentPageKey}
            setCurrentPageKey={setCurrentPageKey}
            theme={
              {
                color: surveyData.surveyData.surveyTheme.brandColor,
                fontFamily: surveyData.surveyData.surveyTheme.font,
                bgColor: surveyData.surveyData.surveyTheme.backgroundColor,
                layout: get(surveyData, 'surveyData.surveyTheme.layout', 'default'),
              } as any
            }
            uploadPicturesRequest={{
              url: apiBaseUrl + `/${endpoints.momos.survey.default}/${slug}/media/upload-v2`,
              surveyId: surveyData.id,
            }}
            surveySettings={
              {
                negativeRating: surveyData?.settings?.negativeRating || [0, 0],
                positiveRating: surveyData?.settings?.positiveRating || [0, 0],
                smsEnabled: data?.smsFeatureEnabled,
                template: surveyTemplate,
                reviewBoosting: get(surveyData, 'settings.reviewBoosting', true),
                showProgressBar: get(surveyData, 'settings.showProgressBar', true),
                itemRejectionList: surveyData?.settings?.itemRejectionList || [0, 0],
              } as any
            }
            // @ts-ignore
            itemList={itemList as any}
            brandId={surveyData?.brandId}
            submitSurvey={(val: any, additonalValues: any) => submitSurveyV2(val, additonalValues)}
            loading={loading}
            expressParams={expressParams as any}
            region={REGION}
            handleAssistedReview={handleAssistedReview}
            brandRegion={data.brandDetails?.region}
            surveyData={surveyData}
            vouchersData={useableVouchers as any}
            multiVoucherIds={surveyData?.voucherIds || []}
          />
        </StyledSurveyWrap>
      </>
    );
  } else {
    return (
      <SurveyVoucherPageWrap $brandColor={brandColor}>
        <MetaHeader
          title={surveyData?.name}
          image={data?.brandDetails?.logoUrl ? data.brandDetails.logoUrl : undefined}
          pixelId={data?.micrositeConfig?.micrositePixelId}
        />
        <Header
          brandDetails={data.brandDetails}
          theme={{
            headerBgColor: brandColor,
            headerTextColor: 'var(--white)',
          }}
          hideMenuLink
          hideHome
        />

        <PageContainer>
          {renderSurvey()}
          <PoweredByMomos />
        </PageContainer>
      </SurveyVoucherPageWrap>
    );
  }
}

const StyledSurveyWrap = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    .mobile-container {
      height: 100% !important;
      margin: 0 !important;
      width: 100% !important;
      min-height: 100% !important;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mobile-container {
      height: 100% !important;
      width: 100% !important;
      margin: 0px !important;
      border-radius: 0px !important;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1366px) {
    .mobile-container {
      height: 100% !important;
      width: 80% !important;
      margin: 0px auto !important;
    }
  }
`;

const PageLoaderWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Function to fetch order item details
async function fetchOrderData({ orderId, orderPlatform, momosOrderId, req, slug }: fetchOrderDataProps) {
  const orderData = {
    itemData: [],
    itemFetchTime: 0,
  };
  const upperedPlatform = toUpper(orderPlatform);
  const start = Date.now();
  if (includes([PARTNER.OLO, PARTNER.PAYTRONIX], upperedPlatform) && momosOrderId) {
    await fetchOrderItemsDetailByMomosOrderId(req, upperedPlatform, momosOrderId, slug)
      .then((response) => {
        console.log('response', response);
        orderData.itemData = response;
        orderData.itemFetchTime = Date.now() - start;
      })
      .catch((err) =>
        log.error(
          { err, momosOrderId, orderPlatform, slug },
          'An error occurred while fetching order items details for survey submission V2',
        ),
      );
  } else {
    if (
      orderId &&
      (orderPlatform === 'NCR' || orderPlatform === 'PAYTRONIX' || orderPlatform === 'OLO') &&
      ITEMS_SALES_BASE_URL &&
      ITEMS_SALES_PUBLIC_TOKEN
    ) {
      try {
        const queryString = qs.stringify({
          platform: orderPlatform,
          orderId,
        });
        const response = await request.get(`${ITEMS_SALES_BASE_URL}/order/item-sales?${queryString}`, {
          headers: {
            Authorization: `Bearer ${ITEMS_SALES_PUBLIC_TOKEN}`,
            'Access-Control-Allow-Origin': '*',
            silentFetch: true,
          },
        });
        if (isEqual(response?.status, 200) && !isEmpty(get(response, 'data'))) {
          orderData.itemData = get(response, 'data');
        }
        orderData.itemFetchTime = Date.now() - start;
      } catch (err) {
        log.error(
          { err, orderId, orderPlatform },
          'An error occurred while fetching order items details for survey submission',
        );
      }
    }
  }

  return orderData;
}

function populateSessionHeaders(
  req: IncomingMessage & { cookies: NextApiRequestCookies },
  params: ParsedUrlQuery | undefined,
) {
  const brandSlug = params?.slug;
  const momosSessionDeviceId = uuidv4();
  const customHeaders = {
    'x-session-id': momosSessionDeviceId || '',
    'x-survey-id': params?.id || '',
    'x-brand-slug': brandSlug || '',
  };
  // Mutate the incoming request headers to include custom headers
  req.headers = { ...req.headers, ...customHeaders };
  return customHeaders;
}

export const getServerSideProps: GetServerSideProps = async ({ params, query, req }) => {
  try {
    const customHeaders = populateSessionHeaders(req, params);
    log.debug({ sessionId: customHeaders['x-session-id'] }, `Inside getServerSideProps ${params?.slug}`);
    const query_ = queryParamCleanUp(query);
    const [surveyResult, micrositeResult, orderResult] = await Promise.allSettled([
      getSurveyData(req, params?.slug, params?.id),
      getMicrositeBySlug(req, params?.slug),
      fetchOrderData({
        orderId: query_?.orderId,
        orderPlatform: query_?.orderSource || query_?.platform,
        momosOrderId: query_?.momosOrderId,
        slug: params?.slug,
        req,
      }),
    ]);

    const surveyData = surveyResult.status === 'fulfilled' ? surveyResult.value : null;
    const micrositeData = micrositeResult.status === 'fulfilled' ? micrositeResult.value : null;
    const orderData = orderResult.status === 'fulfilled' ? orderResult.value : { itemData: [], itemFetchTime: 0 };

    log.debug('surveyData: ', surveyData);
    log.debug('micrositeData', micrositeData);
    log.debug('orderData: ', orderData);

    const voucherIds = surveyData?.surveyData?.voucherIds;
    const voucherId = surveyData?.surveyData?.voucher;
    const voucherIdsToSent = voucherIds && voucherIds?.length ? voucherIds : voucherId?.length ? [voucherId] : [];
    log.debug('voucherIdsToSent: ', voucherIdsToSent);

    const vouchersData = voucherIdsToSent?.length ? await getVouchers(req, voucherIdsToSent) : [];
    log.debug('vouchersData: ', vouchersData);

    return {
      props: {
        data: surveyData,
        vouchersData: vouchersData?.data || [],
        slug: params?.slug,
        micrositeData: micrositeData ?? null,
        orderData,
        customHeaders,
      },
    };
  } catch (err) {
    log.error('An unexpected error occurred:', err);
    return {
      notFound: true,
    };
  }
};
